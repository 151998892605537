@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600);

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

a {
  text-decoration: none;
  color: inherit;
}
